import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Footer, Layout, Navbar, PageHeader, Sponsor } from '@components';
import { graphql, Link } from 'gatsby';
import { orderBy } from 'lodash';
import { renderAbstract } from '@utils';
import '@scss/research-page.scss';

const ResearchListPage = ({ data }) => {
  const { allResearchProjects } = data.postgres;

  const renderProjects = (projects) => orderBy(projects, `paperId`)
    ?.map(item =>
      <Col md={6} lg={3} key={item.id}>
        <div className="presentation">
          <div className="name">
            <h2>{item.title}</h2>
          </div>
          <div className="presentation-overlay">
            <Link to={`/research/${item.id}`}>
              <p>
                { renderAbstract(item.abstract) }
              </p>
            </Link>
          </div>
        </div>
      </Col>);

  return <Layout className="research-page">
    <Navbar year={allResearchProjects[0].year} />
    <PageHeader
      title="Research Symposium Presentations"
      titleTransform="uppercase"
    />
    <Container className="presentations-div">
      {!!allResearchProjects?.filter(item => item.topic === `Research`).length && <div className="outer-div">
        <h2 style={{ padding: `10px`, textAlign: `center` }}>Presentations</h2>
        <hr />
        <Row>
          {renderProjects(allResearchProjects?.filter(item => item.topic === `Research`))}
        </Row>
      </div>}
      {!!allResearchProjects?.filter(item => item.topic === `Poster`).length && <div className="outer-div">
        <h2 style={{ padding: `10px`, textAlign: `center` }}>Posters</h2>
        <hr />
        <Row>
          {renderProjects(allResearchProjects?.filter(item => item.topic === `Poster`))}
        </Row>
      </div>}
      {!!allResearchProjects?.filter(item => item.topic === `10-Minute Talks`).length && <div className="outer-div">
        <h2 style={{ padding: `10px`, textAlign: `center` }}>Presentations</h2>
        <hr />
        <Row>
          {renderProjects(allResearchProjects?.filter(item => item.topic === `10-Minute Talks`))}
        </Row>
      </div>}
      {!!allResearchProjects?.filter(item => item.topic === `Lightning Demos`).length && <div className="outer-div">
        <h2 style={{ padding: `10px`, textAlign: `center` }}>Lightning Demos</h2>
        <hr />
        <Row>
          {renderProjects(allResearchProjects?.filter(item => item.topic === `Lightning Demos`))}
        </Row>
      </div>}
    </Container>
    <Sponsor year={allResearchProjects[0].year} />
    <Footer />

  </Layout>;
};

export const query = graphql`
query ResearchList($year: String) {
  postgres {
    allResearchProjects: allResearchProjectsList(condition: { year: $year }){
      id
      title
      topic
      abstract
      year
      paperId
    }
  }
}
`;

export default ResearchListPage;
